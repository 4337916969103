import React from "react";
import "../../Assets/css/case.css";
import "../../Assets/css/base.css";
import "../../Assets/css/demo1.css";
import "../../Assets/css/home.css";
import SEO from "../../components/seo";
import Parallax from "react-rellax";
import $ from "jquery";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Header from "../../components/header";
import Footer from "../../components/footer";
import ReactPlayer from "react-player";

class ServiceTechnique extends React.Component {
  componentDidMount() {
    require("paroller.js");
    $(document).ready(function() {
      $(".paroller-example").paroller({
        factor: 0.15,
        type: "foreground",
        direction: "vertical"
      });
    });
  }

  render() {
    return (
      <div>
        <SEO
          title="Service Technique · Light weight comedy series backed by Real Technology"
          description=" Service Technique or ( Technical Support ) is a comedy
                    series that ventures into the lives of three employees at a
                    monopoly of an internet provider called AlgerieNet"
          image={
            "https://makook.space" +
            require("../../images/Service-tech/Service-Technique-serie-comedie-TV-cover.jpg")
          }
        />
        <Header />

        <div
          data-post-title="makookcase"
          data-theme="theme-dark"
          className="theme-dark"
        >
          <main className="case makookcase">
            <h1 className="master-title">Service Technique</h1>

            <div
              className="background-color-makookcase-1 color-transition"
              data-color="#000"
            >
              <section className="image-block sticky-title-padding-top mobile-hero no-margin-bottom sticky-stop">
                <figure>
                  <Img fluid={this.props.data.imageOne.childImageSharp.fluid} />
                  <Parallax speed={-2} percentage={0.5} centered>
                    <p className=" desktop-only">
                      Light weight comedy series backed by Real Technology
                    </p>
                  </Parallax>

                  <Parallax speed={-2} percentage={0.5} centered>
                    <p className="mobile-only">
                      Light weight comedy series backed by Real Technology
                    </p>
                  </Parallax>
                </figure>
              </section>

              <section className="image-text push-up half-padding-bottom no-margin-bottom">
                <figure>
                  <Img fluid={this.props.data.imageTwo.childImageSharp.fluid} />
                </figure>
                <article>
                  <span>Date</span>
                  <p>2018</p>
                  <span>Challenge</span>
                  <p>
                    Algeria is the largest country in Africa by size and
                    considered one of the most advanced ones in the continent.
                    as many countries Algeria has problems. One of these
                    problems is internet access, the country has one of the
                    biggest internet infrastructures but its listed among the
                    last 10 countries in the world regarding internet speed.
                    Service technique adresses these issue in a comedic fashion
                    talking about the single internet provider ( Algerie Telecom
                    ) in hopes of getting a message across.
                  </p>
                  <span>Solution</span>
                  <p>
                    Service Technique or ( Technical Support ) is a comedy
                    series that ventures into the lives of three employees at a
                    monopoly of an internet provider called AlgerieNet — The
                    series addresses the the everyday struggles of the
                    customers, it also highlights the solutions given by the
                    employees in a comedic and humorous manner.
                    <br />
                    Saïd, Wahid & SAif Khobz are the main characters, Each one
                    of them represents a certain type of employees / stereotype
                    of the general Algerian population with a slight twist that
                    put the characters in the center of the spot light.
                    <br />
                    the series is made to address the social difficulties
                    Algerians face, yet it also puts the focus on the economical
                    disasters monopolies such as Algerie-Telecom are making
                    their impacts on employment and employee behaviours.
                  </p>
                  <span>Disciplines</span>
                  <p>
                    Cinematography
                    <br />
                    Writing and Directing
                    <br />
                    Design
                    <br />
                    User Experience
                    <br />
                    User Research
                    <br />
                    Acting
                    <br />
                    3D and Animation
                  </p>
                </article>
              </section>

              <section className="mobile-text mobile-only">
                <article>
                  <div>
                    <span>Date</span>
                    <p>2018</p>
                  </div>
                  <div>
                    <span>Challenge</span>
                    <p>
                      Algeria is the largest country in Africa by size and
                      considered one of the most advanced ones in the continent.
                      as many countries Algeria has problems. One of these
                      problems is internet access, the country has one of the
                      biggest internet infrastructures but its listed among the
                      last 10 countries in the world regarding internet speed.
                      Service technique adresses these issue in a comedic
                      fashion talking about the single internet provider (
                      Algerie Telecom ) in hopes of getting a message across.
                    </p>
                  </div>
                  <div>
                    <span>Solution</span>
                    <p>
                      Service Technique or ( Technical Support ) is a comedy
                      series that ventures into the lives of three employees at
                      a monopoly of an internet provider called AlgerieNet — The
                      series addresses the the everyday struggles of the
                      customers, it also highlights the solutions given by the
                      employees in a comedic and humorous manner.
                      <br />
                      Saïd, Wahid & SAif Khobz are the main characters, Each one
                      of them represents a certain type of employees /
                      stereotype of the general Algerian population with a
                      slight twist that put the characters in the center of the
                      spot light.
                      <br />
                      the series is made to address the social difficulties
                      Algerians face, yet it also puts the focus on the
                      economical disasters monopolies such as Algerie-Telecom
                      are making their impacts on employment and employee
                      behaviours.
                    </p>
                  </div>
                  <div>
                    <span>Disciplines</span>
                    <p>
                      Cinematography
                      <br />
                      Writing and Directing
                      <br />
                      Design
                      <br />
                      User Experience
                      <br />
                      User Research
                      <br />
                      Acting
                      <br />
                      3D and Animation
                    </p>
                  </div>
                </article>
              </section>
            </div>

            <div
              className="background-color-makookcase-2 color-transition"
              data-color="#1b1b1b"
            >
              <section className="image-block half-margin-bottom mobile-only">
                <figure>
                  <ReactPlayer
                    className=""
                    url="https://youtu.be/KLYViVbiZDo"
                    width="100%"
                    height="250px"
                  />
                </figure>
              </section>
              <section className="image-block half-margin-bottom desktop-only">
                <figure>
                  <ReactPlayer
                    className=""
                    url="https://youtu.be/KLYViVbiZDo"
                    width="100%"
                    height="720px"
                  />
                </figure>
              </section>

              <section className="makookcase-block-1">
                <h3 className="paroller-example paroller-title desktop-only">
                  A real 80s inspired Internet Speed Test website powered by
                  real AI.
                </h3>
              </section>

              <section className="image-block">
                <figure className="">
                  <Img
                    fluid={this.props.data.imageFour.childImageSharp.fluid}
                  />
                </figure>
              </section>
              <section className="image-block">
                <figure className="">
                  <Img
                    fluid={this.props.data.imageFive.childImageSharp.fluid}
                  />
                </figure>
              </section>

              <section className="image-text half-margin-bottom">
                <figure>
                  <ReactPlayer
                    url={require("../../images/Service-tech/IMG_1774.mp4")}
                    playing
                    muted
                    loop
                    width="100%"
                    height="100%"
                  />

                  <Parallax speed={2}>
                    <h3 className=" mobile-only">
                      Made with passion, dedication, and the will to make a
                      change by leaving the slightest impact.
                    </h3>
                  </Parallax>
                </figure>
                <article>
                  <Parallax speed={-2} percentage={0.5} centered>
                    <h3 className=" desktop-only" data-func="caseIntroParallax">
                      Made with passion, dedication, and the will to to make a
                      change by leaving the slightest impact.
                    </h3>
                  </Parallax>
                </article>
              </section>

              <section className="image-block">
                <figure>
                  <Img fluid={this.props.data.imageSix.childImageSharp.fluid} />
                </figure>
              </section>

              <section className="two-images mobile-margin-hack half-margin-bottom desktop-only">
                <figure className="">
                  <ReactPlayer
                    url="https://youtu.be/KLYViVbiZDo"
                    width="100%"
                    height="350px"
                  />
                </figure>
                <figure className="">
                  <ReactPlayer
                    url="https://youtu.be/iZ0Ue-0hWVs"
                    width="100%"
                    height="350px"
                  />
                </figure>
              </section>

              <section className="two-images desktop-only">
                <figure className="">
                  <ReactPlayer
                    url="https://youtu.be/hC6UVJM07dU"
                    width="100%"
                    height="350px"
                  />
                </figure>
                <figure className="">
                  <ReactPlayer
                    url="https://youtu.be/TbjDTl6W8FU"
                    width="100%"
                    height="350px"
                  />
                </figure>
              </section>

              <section className="image-block mobile-only">
                <figure>
                  <ReactPlayer
                    url="https://youtu.be/iZ0Ue-0hWVs"
                    width="100%"
                    height="350px"
                  />
                </figure>
              </section>

              <section className="image-block mobile-only">
                <figure>
                  <ReactPlayer
                    url="https://youtu.be/hC6UVJM07dU"
                    width="100%"
                    height="350px"
                  />
                </figure>
              </section>

              <section className="image-block half-margin-bottom">
                <figure>
                  <Img
                    fluid={this.props.data.imageThree.childImageSharp.fluid}
                  />
                </figure>
              </section>

              <Footer />
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default ServiceTechnique;

export const ServiceTechniqueImg = graphql`
  fragment ServiceTechniqueImg on File {
    childImageSharp {
      fluid(maxWidth: 1400, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const ServiceTechniqueQuery = graphql`
  query {
    imageOne: file(
      relativePath: {
        eq: "Service-tech/Service-Technique-serie-comedie-TV-cover.jpg"
      }
    ) {
      ...fluidImage
    }

    imageTwo: file(
      relativePath: { eq: "Service-tech/Service-Technique-poster.jpg" }
    ) {
      ...fluidImage
    }

    imageThree: file(
      relativePath: { eq: "Service-tech/Service-Technique-AlgerieNet.jpg" }
    ) {
      ...fluidImage
    }

    imageFour: file(
      relativePath: {
        eq: "Service-tech/makbookpro-internet-speed-test-makook.jpg"
      }
    ) {
      ...fluidImage
    }

    imageFive: file(
      relativePath: { eq: "Service-tech/Internet-speed-test-makook.jpg" }
    ) {
      ...fluidImage
    }

    imageSix: file(
      relativePath: {
        eq: "Service-tech/Service-technique-in-the-making-makook.jpg"
      }
    ) {
      ...fluidImage
    }
  }
`;
